<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ ad_data.id }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.ads.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form ref="form">
                    <v-row>
                        <v-col>
                            <v-text-field label="Name in russian" placeholder="Please enter a name in russian" outlined v-model="ad_data.name_ru" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name in english" placeholder="Please enter a name in english" outlined v-model="ad_data.name_en" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name in armenian" placeholder="Please enter a name in armenian" outlined v-model="ad_data.name_hy" ></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <v-btn-toggle
                                v-model="ad_data.type_id"
                                borderless
                                color="teal accent-3"
                            >
                                <v-btn
                                    v-for="(type, key) in adTypes"
                                    :key="key"
                                    :value="key"
                                >
                                    {{ type }}
                                </v-btn>
                            </v-btn-toggle>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col v-if="adIsImageType">
                            <div>
                                <avatar-edit
                                    :current_url="ad_data.image_url"
                                    v-model="ad_data.image_url"
                                ></avatar-edit>
                            </div>
                            <v-text-field :rules="imageRules" label="Image URL" v-model="ad_data.image_url" disabled></v-text-field>
                        </v-col>
                        <v-col v-else-if="adIsHtmlType">
                            <v-text-field v-model="ad_data.html_code" :rules="htmlRules" outlined label="HTML code" />
                            <ads-html v-if="!isLoading" :ads_data="ad_data" />
                        </v-col>
                    </v-row>
                    <v-row v-if="adIsImageType">
                        <v-col>
                            <v-text-field :rules="linkRules" label="Link URL" v-model="ad_data.link_url"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-switch v-model="ad_data.is_active"
                                        :label="`Visibility: ${ad_data.is_active ? 'Show' : 'Hidden'}`"
                                        :color="ad_data.is_active ? 'teal accent-3' : 'red darken-3'"
                            >
                            </v-switch>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Created at" v-model="ad_data.created_at" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn block color="green" @click="handleSaveAds">Save Changes</v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackbar" color="green" :timeout="timeout_snackbar">{{ text_snackbar }}</v-snackbar>
    </admin-dashboard-layout>
</template>

<script>
import Ad from "@/models/Ad"

export default {
    name: "ads-show",

    components: {
        AdsHtml: () => import('@/components/ads/AdsHtml'),
        AvatarEdit: () => import('@/components/AvatarEdit'),
        AdminDashboardLayout: () => import('@/layouts/AdminDashboardLayout'),
    },

    data() {
        return {
            ad_data: {},
            adTypes: [],
            formValid: true,
            imageRules: [v => !!v && this.adIsImageType || 'Image is required'],
            htmlRules: [v => !!v && this.adIsHtmlType || 'Script Tag is required'],
            linkRules: [v => !!v && this.adIsImageType || 'Link is required'],
            isLoading: false,
            snackbar: false,
            text_snackbar: 'Saved successfully',
            timeout_snackbar: 2000,
        }
    },

    computed: {
        adIsImageType() {
            return this.ad_data.type_id === 0
        },
        adIsHtmlType() {
            return this.ad_data.type_id === 1
        },
        isCreatePage() {
            return !this.$route.params.id || this.$route.params.id  === 'create'
        }
    },

    mounted() {
        this.getAdTypes()

        if (!this.isCreatePage) {
            this.getAd()
        }
    },

    methods: {
        async getAd() {
            this.isLoading = true

            try {
                this.ad_data = await Ad.find(this.$route.params.id)
            } catch (error) {
                console.error(error)
            } finally {
                this.isLoading = false
            }
        },
        async handleSaveAds() {
            this.formValid = this.$refs.form.validate();

            if (!this.formValid) return

            this.isLoading = true

            try {
                const ad = new Ad(this.ad_data)
                this.ad_data = await ad.save()
            } catch (error) {
                console.error(error)
            } finally {
                this.isLoading = false
            }

            if (this.isCreatePage) {
                await this.$router.push({name: 'admin.ads.show', params: { id: this.ad_data.id }})
            }

            this.snackbar = true
        },
        async getAdTypes() {
            const types = await Ad.custom('/ads/types').first()

            for (let key in types) {
                this.adTypes.push(types[key])
            }
        }
    },
}
</script>

<style scoped>

</style>
